@import '../../../css/common_vars';

$card-color-text: lighten($color_main, 75%);
$card-color-bg: $color_main;
$card-height: 10rem;
$small-card-height: 6rem;
$small-card-height-vertical: 8rem;
$filler-length: 40rem;

.InstructionCard {
  height: $card-height;
  margin-left: 1rem;
  margin-bottom: 2rem;

  padding-right: 1rem;

  color: $card-color-text;
  background-color: $card-color-bg;

  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;

  .left-filler {
    z-index: -1;

    position: absolute;
    height: $card-height;
    width: $filler-length;
    left: -$filler-length + 1rem;

    background-color: $card-color-bg;
  }

  .text {
    .count {
      font-size: 1.3rem;
    }

    .content {
      font-weight: 700;
      font-size: 2rem;

      &.markdown {
        p:first-child {
          margin-top: 0;
        }

        p:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &.small {
    height: $small-card-height;

    .left-filler {
      height: $small-card-height;
    }
  }
}

.vertical .InstructionCard {
  &.small {
    height: $small-card-height-vertical;

    .left-filler {
      height: $small-card-height-vertical;
    }
  }
}